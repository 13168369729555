import React, { useState, useEffect, useRef } from "react";

import Downloader from "./Downloader";

import { Box, Button, Grid, Stack, Typography } from "@mui/material";

import logo from "./images/pdlogo.png";

import Patterns from "./Patterns";
import PatternMenu from "./PatternMenu";
import Slider from "./Slider";
import Switch from "./Switch";

import ScaleToWidth from "./ScaleToWidth";

export default function App() {
   const [testPattern, setTestPattern] = React.useState("");

   const [circles, setCircles] = useState(false);
   const [text, setText] = useState(false);

   const [columns, setColumns] = React.useState(2);
   const [rows, setRows] = React.useState(2);

   const patternRef = useRef(null);

   return (
      <Box sx={{ p: 4 }}>
         <Stack
            direction='row'
            spacing={4}
            justifyContent='flex-start'
            alignItems='center'
            sx={{
               mb: 2,
               p: 4,
               borderRadius: 1,
               backgroundColor: "rgba(255,255,255,.9)",
            }}
         >
            <img src={logo} width={120} />

            <PatternMenu pattern={testPattern} callback={setTestPattern} />

            {testPattern && (
               <Downloader
                  enabled={true}
                  downloadName={"TestPattern_" + testPattern}
               >
                  <Patterns
                     pattern={testPattern}
                     columns={columns}
                     rows={rows}
                     circles={circles}
                     text={text}
                  />
               </Downloader>
            )}
         </Stack>

         {testPattern == "grid" && (
            <Stack
               direction='row'
               spacing={4}
               justifyContent='flex-start'
               alignItems='center'
               sx={{
                  mb: 2,
                  px: 4,
                  py: 2,
                  borderRadius: 1,
                  backgroundColor: "rgba(255,255,255,.7)",
               }}
            >
               <Slider label='Columns' value={columns} callback={setColumns} />
               <Slider label='Rows' value={rows} callback={setRows} />

               <Switch label='Circles' value={circles} callback={setCircles} />
               <Switch label='Text' value={text} callback={setText} />
            </Stack>
         )}

         <ScaleToWidth contentWidth={1920} contentHeight={1080}>
            {(parent) => (
               <Patterns
                  pattern={testPattern}
                  columns={columns}
                  rows={rows}
                  circles={circles}
                  text={text}
               />
            )}
         </ScaleToWidth>
      </Box>
   );
}
