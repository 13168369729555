import { useState, useEffect } from "react";

import logo from "./images/pdlogo.png";
import { Box, Stack } from "@mui/material";

const generatePattern = (pattern, columns, rows, circles, text) => {
   switch (pattern) {
      case "colorBars":
         return (
            <Stack
               sx={{ width: "100%", height: "100%" }}
               alignItems='flex-start'
            >
               <Stack
                  direction='row'
                  justifyContent='flex-start'
                  alignItems='stretch'
                  sx={{ width: "100%", height: "60%" }}
               >
                  <Box
                     sx={{
                        backgroundColor: "rgb(104,104,104)",
                        width: "11.111111%",
                     }}
                  />
                  <Box
                     sx={{
                        backgroundColor: "rgb(180,180,180)",
                        width: "11.111111%",
                     }}
                  />
                  <Box
                     sx={{
                        backgroundColor: "rgb(180,180,16)",
                        width: "11.111111%",
                     }}
                  />
                  <Box
                     sx={{
                        backgroundColor: "rgb(16,180,180)",
                        width: "11.111111%",
                     }}
                  />
                  <Box
                     sx={{
                        backgroundColor: "rgb(16,180,16)",
                        width: "11.111111%",
                     }}
                  />
                  <Box
                     sx={{
                        backgroundColor: "rgb(180,16,180)",
                        width: "11.111111%",
                     }}
                  />
                  <Box
                     sx={{
                        backgroundColor: "rgb(180,16,16)",
                        width: "11.111111%",
                     }}
                  />
                  <Box
                     sx={{
                        backgroundColor: "rgb(16,16,180)",
                        width: "11.111111%",
                     }}
                  />
                  <Box
                     sx={{
                        backgroundColor: "rgb(104,104,104)",
                        width: "11.111111%",
                     }}
                  />
               </Stack>
               <Stack
                  direction='row'
                  justifyContent='flex-start'
                  alignItems='stretch'
                  sx={{ width: "100%", height: "10%" }}
               >
                  <Box
                     sx={{
                        backgroundColor: "rgb(16,235,235)",
                        width: "11.111111%",
                     }}
                  />
                  <Box
                     sx={{
                        backgroundColor: "rgb(16,70,106)",
                        width: "11.111111%",
                     }}
                  />
                  <Box
                     sx={{
                        backgroundColor: "rgb(180,180,180)",
                        width: "66.6666667%",
                     }}
                  />
                  <Box
                     sx={{
                        backgroundColor: "rgb(16,16,235)",
                        width: "11.111111%",
                     }}
                  />
               </Stack>
               <Stack
                  direction='row'
                  justifyContent='flex-start'
                  alignItems='stretch'
                  sx={{ width: "100%", height: "10%" }}
               >
                  <Box
                     sx={{
                        backgroundColor: "rgb(235,235,16)",
                        width: "11.111111%",
                     }}
                  />
                  <Box
                     sx={{
                        backgroundColor: "rgb(72,16,118)",
                        width: "11.111111%",
                     }}
                  />
                  <Box
                     sx={{
                        background: "linear-gradient(90deg, black, white);",
                        width: "66.6666667%",
                     }}
                  />
                  <Box
                     sx={{
                        background: "rgb(235,16,16)",
                        width: "11.111111%",
                     }}
                  />
               </Stack>
               <Stack
                  direction='row'
                  justifyContent='flex-start'
                  alignItems='stretch'
                  sx={{ width: "100%", height: "20%" }}
               >
                  <Box
                     sx={{
                        backgroundColor: "rgb(47,47,47)",
                        width: "11.111111%",
                     }}
                  />
                  <Box
                     sx={{
                        backgroundColor: "rgb(0,0,0)",
                        width: "11.111111%",
                     }}
                  />
                  <Box
                     sx={{
                        backgroundColor: "rgb(255,255,255)",
                        width: "22.222222%",
                     }}
                  />
                  <Box
                     sx={{
                        backgroundColor: "rgb(0,0,0)",
                        width: "44.4444444%",
                     }}
                  />
                  <Box
                     sx={{
                        backgroundColor: "rgb(47,47,47)",
                        width: "11.111111%",
                     }}
                  />
               </Stack>
            </Stack>
         );
         break;
      case "colorBarsPD":
         return (
            <>
               <Box
                  sx={{
                     backgroundColor: "rgba(255,255,255,1)",
                     py: 10,
                     px: 20,
                     borderRadius: 10,
                     mx: "auto",
                     position: "absolute",
                     left: "50%",
                     top: "12%",
                     transform: "translateX(-50%)",
                  }}
               >
                  <img src={logo} width='400' />
               </Box>
               <Stack
                  sx={{ width: "100%", height: "100%" }}
                  alignItems='flex-start'
               >
                  <Stack
                     direction='row'
                     justifyContent='flex-start'
                     alignItems='stretch'
                     sx={{ width: "100%", height: "60%" }}
                  >
                     <Box
                        sx={{
                           backgroundColor: "rgb(104,104,104)",
                           width: "11.111111%",
                        }}
                     />
                     <Box
                        sx={{
                           backgroundColor: "rgb(180,180,180)",
                           width: "11.111111%",
                        }}
                     />
                     <Box
                        sx={{
                           backgroundColor: "rgb(180,180,16)",
                           width: "11.111111%",
                        }}
                     />
                     <Box
                        sx={{
                           backgroundColor: "rgb(16,180,180)",
                           width: "11.111111%",
                        }}
                     />
                     <Box
                        sx={{
                           backgroundColor: "rgb(16,180,16)",
                           width: "11.111111%",
                        }}
                     />
                     <Box
                        sx={{
                           backgroundColor: "rgb(180,16,180)",
                           width: "11.111111%",
                        }}
                     />
                     <Box
                        sx={{
                           backgroundColor: "rgb(180,16,16)",
                           width: "11.111111%",
                        }}
                     />
                     <Box
                        sx={{
                           backgroundColor: "rgb(16,16,180)",
                           width: "11.111111%",
                        }}
                     />
                     <Box
                        sx={{
                           backgroundColor: "rgb(104,104,104)",
                           width: "11.111111%",
                        }}
                     />
                  </Stack>
                  <Stack
                     direction='row'
                     justifyContent='flex-start'
                     alignItems='stretch'
                     sx={{ width: "100%", height: "10%" }}
                  >
                     <Box
                        sx={{
                           backgroundColor: "rgb(16,235,235)",
                           width: "11.111111%",
                        }}
                     />
                     <Box
                        sx={{
                           backgroundColor: "rgb(16,70,106)",
                           width: "11.111111%",
                        }}
                     />
                     <Box
                        sx={{
                           backgroundColor: "rgb(180,180,180)",
                           width: "66.6666667%",
                        }}
                     />
                     <Box
                        sx={{
                           backgroundColor: "rgb(16,16,235)",
                           width: "11.111111%",
                        }}
                     />
                  </Stack>
                  <Stack
                     direction='row'
                     justifyContent='flex-start'
                     alignItems='stretch'
                     sx={{ width: "100%", height: "10%" }}
                  >
                     <Box
                        sx={{
                           backgroundColor: "rgb(235,235,16)",
                           width: "11.111111%",
                        }}
                     />
                     <Box
                        sx={{
                           backgroundColor: "rgb(72,16,118)",
                           width: "11.111111%",
                        }}
                     />
                     <Box
                        sx={{
                           background: "linear-gradient(90deg, black, white);",
                           width: "66.6666667%",
                        }}
                     />
                     <Box
                        sx={{
                           background: "rgb(235,16,16)",
                           width: "11.111111%",
                        }}
                     />
                  </Stack>
                  <Stack
                     direction='row'
                     justifyContent='flex-start'
                     alignItems='stretch'
                     sx={{ width: "100%", height: "20%" }}
                  >
                     <Box
                        sx={{
                           backgroundColor: "rgb(47,47,47)",
                           width: "11.111111%",
                        }}
                     />
                     <Box
                        sx={{
                           backgroundColor: "rgb(0,0,0)",
                           width: "11.111111%",
                        }}
                     />
                     <Box
                        sx={{
                           backgroundColor: "rgb(255,255,255)",
                           width: "22.222222%",
                        }}
                     />
                     <Box
                        sx={{
                           backgroundColor: "rgb(0,0,0)",
                           width: "44.4444444%",
                        }}
                     />
                     <Box
                        sx={{
                           backgroundColor: "rgb(47,47,47)",
                           width: "11.111111%",
                        }}
                     />
                  </Stack>
               </Stack>
            </>
         );
         break;
      case "grid":
         const width = 1920 / columns;
         const height = 1080 / rows;

         return (
            <>
               {text && (
                  <>
                     <Box
                        sx={{
                           fontSize: "4rem",
                           top: "20%",
                           left: "50%",
                           position: "absolute",
                           zIndex: 1500,
                           color: "#fff",
                           textAlign: "center",
                           transform: "translateX(-50%) translateY(-50%)",
                        }}
                     >
                        ABCDEFGHIJKLMNOPQRSTUVWXYZ
                        <br />
                        abcdefghijklmnopqrstuvwxyz
                        <br />
                        1234567890
                     </Box>
                     <Box
                        sx={{
                           fontSize: "3rem",
                           top: "50%",
                           left: "50%",
                           position: "absolute",
                           zIndex: 1500,
                           color: "#fff",
                           textAlign: "center",
                           transform: "translateX(-50%) translateY(-50%)",
                        }}
                     >
                        ABCDEFGHIJKLMNOPQRSTUVWXYZ
                        <br />
                        abcdefghijklmnopqrstuvwxyz
                        <br />
                        1234567890
                     </Box>
                     <Box
                        sx={{
                           fontSize: "2rem",
                           top: "73%",
                           left: "50%",
                           position: "absolute",
                           zIndex: 1500,
                           color: "#fff",
                           textAlign: "center",
                           transform: "translateX(-50%) translateY(-50%)",
                        }}
                     >
                        ABCDEFGHIJKLMNOPQRSTUVWXYZ
                        <br />
                        abcdefghijklmnopqrstuvwxyz
                        <br />
                        1234567890
                     </Box>
                     <Box
                        sx={{
                           fontSize: "1rem",
                           top: "90%",
                           left: "50%",
                           position: "absolute",
                           zIndex: 1500,
                           color: "#fff",
                           textAlign: "center",
                           transform: "translateX(-50%) translateY(-50%)",
                        }}
                     >
                        ABCDEFGHIJKLMNOPQRSTUVWXYZ
                        <br />
                        abcdefghijklmnopqrstuvwxyz
                        <br />
                        1234567890
                     </Box>
                  </>
               )}
               {circles && (
                  <>
                     <Box
                        sx={{
                           top: "50%",
                           left: "50%",
                           width: 800,
                           height: 800,
                           border: "1px solid #fff",
                           position: "absolute",
                           zIndex: 1200,
                           borderRadius: 100,
                           transform: "translateX(-50%) translateY(-50%)",
                        }}
                     />
                     <Box
                        sx={{
                           top: 11,
                           left: 11,
                           width: 300,
                           height: 300,
                           border: "1px solid #fff",
                           position: "absolute",
                           zIndex: 1200,
                           borderRadius: 100,
                           textAlign: "center",
                        }}
                     >
                        <Box sx={{ pt: 13 }}>
                           <img src={logo} width='200' />
                        </Box>
                     </Box>
                     <Box
                        sx={{
                           top: 11,
                           right: 11,
                           width: 300,
                           height: 300,
                           border: "1px solid #fff",
                           position: "absolute",
                           zIndex: 1200,
                           borderRadius: 100,
                           textAlign: "center",
                        }}
                     >
                        <Box sx={{ pt: 13 }}>
                           <img src={logo} width='200' />
                        </Box>
                     </Box>
                     <Box
                        sx={{
                           bottom: 11,
                           left: 11,
                           width: 300,
                           height: 300,
                           border: "1px solid #fff",
                           position: "absolute",
                           zIndex: 1200,
                           borderRadius: 100,
                           textAlign: "center",
                        }}
                     >
                        <Box sx={{ pt: 13 }}>
                           <img src={logo} width='200' />
                        </Box>
                     </Box>
                     <Box
                        sx={{
                           bottom: 11,
                           right: 11,
                           width: 300,
                           height: 300,
                           border: "1px solid #fff",
                           position: "absolute",
                           zIndex: 1200,
                           borderRadius: 100,
                           textAlign: "center",
                        }}
                     >
                        <Box sx={{ pt: 13 }}>
                           <img src={logo} width='200' />
                        </Box>
                     </Box>
                  </>
               )}

               <Box
                  sx={{
                     width: "100%",
                     height: "100%",
                     border: "10px solid #f00",
                     position: "absolute",
                     zIndex: 1200,
                  }}
               />
               <Box
                  sx={{
                     width: 3,
                     height: "100%",
                     border: "1px solid #f00",
                     position: "absolute",
                     left: "50%",
                     transform: "translateX(-50%)",
                     zIndex: 1000,
                  }}
               />
               <Box
                  sx={{
                     height: 3,
                     width: "100%",
                     border: "1px solid #f00",
                     position: "absolute",
                     top: "50%",
                     transform: "translateY(-50%)",
                     zIndex: 1000,
                  }}
               />
               <Box
                  sx={{
                     height: "100%",
                     width: "54%",
                     border: "2px dashed yellow",
                     position: "absolute",
                     left: "50%",
                     transform: "translateX(-50%)",
                     zIndex: 1000,
                  }}
               />

               <Stack
                  sx={{
                     width: "100%",
                     height: "100%",
                     backgroundColor: "#333",
                  }}
                  alignItems='flex-start'
               >
                  {[...Array(rows)].map((e, r) => (
                     <Stack
                        direction='row'
                        justifyContent='flex-start'
                        alignItems='stretch'
                        sx={{
                           width: "100%",
                           height: "100%",
                        }}
                     >
                        {[...Array(columns)].map((e, c) => (
                           <Box
                              key={"row" + r + "-column" + c}
                              sx={{
                                 border: "1px solid #666",
                                 width: width,
                                 height: height,
                              }}
                           />
                        ))}
                     </Stack>
                  ))}
               </Stack>
            </>
         );
         break;
      case "safety":
         return (
            <Box
               sx={{
                  backgroundColor: "#333",
                  width: "100%",
                  height: "100%",
                  p: 4,
                  fontWeight: "bold",
                  fontSize: "2rem",
                  color: "#ccc",
               }}
            >
               16:9
               <Box
                  sx={{
                     top: 0,
                     left: 0,
                     width: "100%",
                     height: "100%",
                     border: "10px solid #f00",
                     position: "absolute",
                     zIndex: 1200,
                  }}
               />
               <Box
                  sx={{
                     p: 4,
                     color: "rgba(255,0,0,.4)",
                     border: "4px dashed #f00",
                     width: "100%",
                     height: "80%",
                     position: "absolute",
                     top: 0,
                     left: 0,
                     zIndex: 1000,
                  }}
               >
                  <Box
                     sx={{
                        position: "absolute",
                        bottom: 20,
                        width: "100%",
                        textAlign: "center",
                     }}
                  >
                     Caption Safety
                  </Box>
               </Box>
               <Box
                  sx={{
                     p: 4,
                     fontWeight: "bold",
                     fontSize: "2rem",
                     color: "rgba(255,255,255,.2)",
                     border: "4px dashed rgba(255,255,255,.2)",
                     width: 1440,
                     height: 1080,
                     position: "absolute",
                     top: "50%",
                     left: "50%",
                     transform: "translateX(-50%) translateY(-50%)",
                  }}
               >
                  4:3
               </Box>
            </Box>
         );
         break;
      default:
         return (
            <Stack
               sx={{ width: "100%", height: "100%" }}
               alignItems='center'
               justifyContent='center'
            >
               <Box sx={{ fontSize: 100, color: "#efefef" }}>
                  Select a Pattern
               </Box>
            </Stack>
         );
   }
};

export default function Patterns({ pattern, columns, rows, circles, text }) {
   return (
      <Box
         sx={{
            width: 1920,
            height: 1080,
            border: "1px solid #000",
            backgroundColor: "#333",
         }}
      >
         {generatePattern(pattern, columns, rows, circles, text)}
      </Box>
   );
}
