import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

export default function SwitchLabels({ value, label, callback }) {
   return (
      <FormGroup>
         <FormControlLabel
            control={
               <Switch
                  checked={value}
                  onChange={(e) => callback(e.target.checked)}
               />
            }
            label={label}
            labelPlacement='start'
         />
      </FormGroup>
   );
}
