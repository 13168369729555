import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function PatternMenu({ pattern, callback }) {
   const handleChange = (event) => {
      callback(event.target.value);
   };

   return (
      <Box sx={{ width: 300 }}>
         <FormControl fullWidth>
            <InputLabel>Pattern</InputLabel>
            <Select
               value={pattern}
               label='Pattern'
               onChange={handleChange}
               placeholder='Select Pattern'
            >
               <MenuItem value={"colorBarsPD"}>
                  Color Bars - Punch Drunk
               </MenuItem>
               <MenuItem value={"colorBars"}>Color Bars</MenuItem>
               <MenuItem value={"grid"}>Grid</MenuItem>
               <MenuItem value={"safety"}>Safety</MenuItem>
            </Select>
         </FormControl>
      </Box>
   );
}
